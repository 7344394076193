import './styles/index.scss';
import { lazy, Suspense } from 'react';
import { AuthProvider } from './contexts/AuthContext';
import { Route, Navigate, Outlet, BrowserRouter as Router, Routes } from 'react-router-dom';

const Load = lazy(() => import('./pages/Load'));
const Drivers = lazy(() => import('./pages/Drivers'));
const Header = lazy(() => import('./components/Header'));
const Login = lazy(() => import('./pages/auth/Login'));
const EditLoad = lazy(() => import('./pages/Loads/EditLoad'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Create = lazy(() => import('./pages/Loads/Create'));
const Admin = lazy(() => import('./pages/Admin'));
const renderLoader = () => <p>Loading</p>;

function App() {
	function Wrapper() {
		return (
			<div className="mainContent">
				<Outlet />
			</div>
		)
	}

	return (
		<Suspense fallback={renderLoader()}>
			<Router>
				<AuthProvider>
					<div className="App">
						<Header />
						<Routes>
							<Route element={<Wrapper />}>
								<Route path="/" exact element={<Navigate to="/dashboard" />} />
								<Route path="/dashboard" exact element={<Dashboard />} />
								<Route path="/Home" element={<Navigate to="/" />} />
								<Route path="/admin" element={<Admin />} />
								<Route path="/load/create" exact element={<Create />} />
								<Route path="/load/:loadId" exact element={<Load />} />
								<Route path="/load/edit/:loadId" exact element={<EditLoad />} />
								<Route path="/auth/signin" element={<Login />} />
								<Route path="/drivers" element={<Drivers />} />
							</Route>
						</Routes>
					</div>
				</AuthProvider>
			</Router>
		</Suspense>
	);
}

export default App;
